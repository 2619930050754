.ols-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.ols-summary {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.ols-accordian-detail {
  overflow-y: auto;
  overflow-x: hidden;
}

.upload-dem-input {
  width: 100%;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  position: absolute;
}