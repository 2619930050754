/* main */
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.flex-center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-content {
  display: flex;
  height: calc(100% - 54px);
}

.main {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 272px);
  height: 100%;
}

.content-area {
  display: flex;
  height: 100%;
  position: relative;
  background-color: var(--white);
  overflow: auto;
}

.echart-content {
  height: 100vh;
  display: flex;
  overflow: auto;
  background-color: var(--white);
}

.crc-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--white);
  padding: 0 24px 24px;
}

.echart-content-area {
  position: relative;
  flex: 1;
  border-radius: 5px;
  background-color: #dddddd;
  overflow: auto;
}

.flex-direction-column {
  flex-direction: column;
}

/* . {
  background: linear-gradient(107.54deg, var(--primary) 36.98%, #0f111b 100%);
} */

.white-bg {
  background: var(--white);
}

.pt-55 {
  padding-top: 55px;
}

.full-screen-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.full-screen-loader-content {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* sidebar */
.sidebar {
  background-color: var(--white);
  width: 240px;
  padding: 32px 16px;
  position: relative;
  z-index: 1;
  box-shadow: 2px 0px 8px 0px #00000026;
}

.enroute-sidebar {
  padding-top: 14px !important;
}

.check-list-header {
  margin-bottom: 12px;
}

.check-list-action {
  display: flex;
  justify-content: space-between;
}

.check-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  overflow: auto;
  word-break: break-all;
  height: calc(100vh - 355px - 55px);
}

.etod-check-list,
.ols-check-list {
  height: calc(100vh - 350px);
}

.generate-etod-check-list {
  height: calc(100vh - 400px);
}

.check-list::-webkit-scrollbar {
  height: 4px;
  width: 3px;
  background-color: var(--white);
}

.check-list::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 10px;
}

.check-list-1 {
  height: calc(100vh - 332px);
}

.aixm-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.surveyor-sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 212px);
}

.admin-sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.login-sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.amdb-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
}

/* .etod-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.aixm-check-list {
  height: calc(100vh - 460px);
}

.airspace-check-list {
  height: calc(100vh - 504px);
  border: 0.5px solid var(--grey);
  border-radius: 2px;
}

.airspace-check-list-2 {
  height: calc(100vh - 490px);
}

.airspace-check-list-2-2 {
  border: 0.5px solid var(--grey);
  border-radius: 2px;
}

.airspace-check-list-3 {
  height: calc(100vh - 572px);
}

.airspace-check-list-4-4 {
  border: 0.5px solid var(--grey);
  border-radius: 2px;
}

.airspace-check-list-4 {
  height: calc(100vh - 658px);
}

.airspace-check-list-5 {
  height: calc(100% - 37px);
  padding-bottom: 16px;
  border-bottom: 0.5px solid var(--grey);
}

.airspace-check-list-5-5 {
  height: 100%;
  padding-bottom: 0;
  border-bottom: 0;
}

.etod-check-list-area {
  height: calc(100vh - 454px);
}

.amdb-check-list {
  height: calc(100vh - 317px);
  border: 0.5px solid var(--grey);
  border-radius: 2px;
}

.echart-check-list {
  height: calc(100% - 128px);
  padding-bottom: 16px;
}

.review-check-list {
  height: calc(100vh - 262px);
}

/* header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 1px 6px 0px #00000026;
  position: relative;
  z-index: 2;
  height: 54px;
}

/* nav */
.active-menu {
  color: var(--primary);
  font-size: 16px;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.active-menu::after {
  content: "";
  width: 100%;
  top: 34.5px;
  height: 3px;
  background: var(--primary);
  position: absolute;
}

.in-active-menu {
  color: #c4c4c4;
  font-size: 14px;
}

@media screen and (max-width: 1330px) {
  .active-menu {
    font-size: 14px;
  }

  .in-active-menu {
    font-size: 12px;
  }
}

/* brand logo */
.logo-brand {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  width: 272px;
}

.satsure-img {
  height: 40px;
  padding-left: 40px;
}

/* leaflet map */
.leaflet-container {
  width: 100%;
}

.icon-export-print {
  cursor: pointer;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMTI4LDMyaDI1NnY2NEgxMjhWMzJ6IE00ODAsMTI4SDMyYy0xNy42LDAtMzIsMTQuNC0zMiwzMnYxNjBjMCwxNy42LDE0LjM5OCwzMiwzMiwzMmg5NnYxMjhoMjU2VjM1Mmg5NiAgIGMxNy42LDAsMzItMTQuNCwzMi0zMlYxNjBDNTEyLDE0Mi40LDQ5Ny42LDEyOCw0ODAsMTI4eiBNMzUyLDQ0OEgxNjBWMjg4aDE5MlY0NDh6IE00ODcuMTk5LDE3NmMwLDEyLjgxMy0xMC4zODcsMjMuMi0yMy4xOTcsMjMuMiAgIGMtMTIuODEyLDAtMjMuMjAxLTEwLjM4Ny0yMy4yMDEtMjMuMnMxMC4zODktMjMuMiwyMy4xOTktMjMuMkM0NzYuODE0LDE1Mi44LDQ4Ny4xOTksMTYzLjE4Nyw0ODcuMTk5LDE3NnoiIGZpbGw9IiMwMDAwMDAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
}

::-webkit-scrollbar {
  height: 4px;
  width: 3px;
  background: var(--white);
}

::-webkit-scrollbar-thumb:horizontal {
  background: var(--grey);
  border-radius: 10px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.navaid-summary {
  border-bottom: 1px solid #0000001f !important;
}

/* amdb map */
.amdb-marking-mode {
  width: 238px;
  position: absolute;
  display: flex;
  left: 38%;
  top: 10px;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background-color: #141414;
  border-radius: 2px;
  padding: 7px 10px;
}

.amdb-marking-mode p {
  color: var(--white);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.leaflet-div-icon {
  background-color: #43ffdd;
  border: 0;
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
  margin-left: -5px !important;
  margin-top: -5px !important;
}

.custom-draw-control {
  position: absolute;
  z-index: 999;
  border-radius: 4px;
  color: var(--white);
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 2px;
  gap: 10px;
}

.remove-tooltip {
  background-color: #141414 !important;
  padding: 8px 8px;
  gap: 8px;
}

.custom-draw-control button {
  padding: 0 !important;
  padding-right: 1.6px !important;
  height: 27px !important;
  min-width: 27px !important;
  background-color: rgba(255, 255, 255, 0.88) !important;
}

.custom-draw-control button:hover {
  background-color: var(--white) !important;
}

.draw-control-tooltip {
  background-color: #141414 !important;
  padding-left: 15px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  width: 230px !important;
}

.polygon-popup {
  width: 210px !important;
}

.polygon-popup .leaflet-popup-content-wrapper {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
  width: 210px !important;
}

.polygon-popup .leaflet-popup-content {
  padding: 20px 5px 5px !important;
  margin: 0 !important;
  width: 200px !important;
}

.confirmation-dialog-2 .MuiDialog-paper {
  width: 362px;
  padding: 24px 30px;
}

.confirmation-dialog-2 .MuiTypography-h6 {
  padding: 0;
  margin-bottom: 20px;
}

.confirmation-dialog-2 .MuiDialogContent-root {
  padding: 0;
  margin-bottom: 26px;
}

.confirmation-dialog-2 .MuiDialogActions-root {
  direction: rtl;
  display: flex;
  justify-content: flex-start;
}

.confirmation-dialog-2 .MuiDialogActions-root button:last-child {
  margin-right: 20px;
  margin-left: 0px;
}

/* leaflet control */
.leaflet-control-layers-toggle {
  width: 30px !important;
  height: 30px !important;
}

.leaflet-control-scale {
  margin-bottom: 25px !important;
}

.route-plan-undo{
  background-color: #C7C7CC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  margin-top: -22px;
  z-index: 9999;
  cursor: pointer;
  pointer-events: all;
}
.route-plan-undo:before{
  display: none;
}

/* .leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before{
border: 0;
} */

.alwaysOpen .leaflet-popup-content-wrapper {
  display: block;
}

.alwaysOpen .leaflet-popup-tip {
  display: none;
}