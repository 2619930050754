.nav-button-container {
  width: 193px;
  height: 175px;
  background: linear-gradient(129.43deg, #302b9b 21.35%, #235eac 100%);
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.button-label {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: var(--white);
}
.button-line {
  height: 1px;
  background-color: var(--white);
  width: 100%;
  margin-top: 16px;
  margin-bottom: 10px;
}
.side-rect {
  background: #bababa;
  border-radius: 20px;
  position: relative;
  left: -20px;
  top: -20px;
  width: 5px;
  height: 47px;
}
.icon-row {
  flex-direction: row;
  display: flex;
}

/* NavButton1 css */

.nav-button1-container {
  width: 288px;
  height: 288px;
  background: var(--white);
  padding: 0 15px;
  cursor: pointer;
}
.nav-button1-container .MuiDivider-fullWidth {
  display: none;
}
.nav-button1-container:hover {
  box-shadow: 0px 20px 30px -7px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}
.nav-button1-container:hover .MuiDivider-fullWidth{
   display: block;
   background-color: var(--primary);
}
.nav-button1-container:hover svg path {
  fill: var(--primary);
}
.manage-label {
  margin-top: 25px;
  color: var(--lightgrey);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px !important;
}
.nav-button1-container:hover .manage-data-icon path {
  stroke: var(--primary) !important;
  fill: none !important;
}
.nav-button1-container:hover .manage-label {
  color: var(--primary) !important;
}
.manage-icon {
  display: flex;
  justify-content: center;
  padding: 64px 0;
}