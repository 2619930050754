.airspace-category-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.add-airport-data-form-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: calc(100% - 16px);
}
.add-airport-content {
  /* height: calc(100% - 34px); */
  height: calc(100% - 48px);
  background-color: transparent;
}
.add-airport-data-form {
  background-color: transparent !important;
  /* padding-top: 16px; */
  height: 100%;
  box-shadow: none !important;
}
.admin-dashboard-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-dashboard-btn {
  width: 250px !important;
  height: var(--btnheight32) !important;
  background: var(--white) !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}
.admin-dashboard-btn:disabled {
  width: 250px !important;
  height: 32px !important;
  color: var(--grey) !important;
  border: 1px solid var(--grey) !important;
}
.admin-dashboard-btn:hover {
  background: none !important;
}
.welcome-aai-area {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #424442;
}
.ad2-data-text {
  margin-top: 156px;
  margin-bottom: 80px;
}
.create-user-btn{
  margin-top: 0 !important;
  height: 32px;
}
