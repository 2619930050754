.echart-option-tree {
  border: 0.5px solid var(--grey);
  border-radius: 4px;
}

.echart-option-tree .echart-option-tree-item div {
  width: inherit;
  padding: 2.5px;
}

.echart-option-tree-item .MuiTreeItem-content.Mui-selected{
  background-color: var(--white) !important;
  border-radius: 4px;
}

.echart-option-tree .echart-option-tree-item .Mui-expanded {
  border-bottom: 0.5px solid var(--grey);
}

.echart-option-tree .echart-option-tree-item ul {
  margin: 0;
}

.echart-option-tree .echart-option-tree-item ul .MuiCollapse-wrapper {
  width: 100%;
}

.echart-option-tree
  .echart-option-tree-item
  ul
  .MuiCollapse-wrapper
  .MuiCollapse-wrapperInner
  label {
  margin-left: 15px;
}

.echart-option-tree .echart-option-tree-item .MuiCollapse-vertical div {
  padding: 0;
  border-bottom: 0;
}

.echart-option-tree .box-footer {
  border-top: 0.5px solid var(--grey);
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.echart-option-tree .box-footer .box-footer-label {
  border-right: 0.5px solid var(--grey);
}

.echart-option-tree .box-footer .box-footer-label,
.echart-option-tree .box-footer .box-footer-tabel {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.radio-label {
  color: #696b72;
  font-size: 14px;
  font-weight: 400;
}

.echart-option-tree .box-footer .box-footer-label .MuiTypography-span,
.echart-option-tree .box-footer .box-footer-tabel .MuiTypography-span {
  padding-right: 5px;
  padding-left: 8px;
  color: #95969d;
  font-size: 14px;
  font-weight: 400;
}

.echart-option-tree .box-footer .MuiSwitch-thumb {
  background-color: var(--white);
}

.echart-option-tree .box-footer .MuiSwitch-track {
  background-color: #6e3bd1;
}

.echart-option-tree .box-footer .MuiSwitch-track {
  background-color: #c0c0c0;
}
