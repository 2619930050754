.enroute-main {
  height: 100%;
}

.enroute-tablist {
  background-color: #f7f7f7;
  min-height: 40px !important;
  border: 0.1px solid #f7f7f7;
}

.enroute-tablist div {
  height: 40px;
}

.enroute-airspace-main .MuiTabs-fixed > span {
  height: 3px;
  background-color: var(--tertiary);
}

.enroute-airspace-tab {
  width: 50%;
  height: 40px;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: var(--secondary) !important;
  padding-bottom: 15px !important;
  text-transform: capitalize !important;
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.enroute-active-tab {
  background-color: var(--white) !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px 2px 2px 0px !important;
  color: var(--tertiary) !important;
}

.enroute-airspace-tabpanel {
  height: calc(100% - 136px);
  padding: 0 !important;
  padding-right: 2px !important;
  margin-top: 16px;
  overflow: auto;
}

.enroute-airspace-tabpanel::-webkit-scrollbar,
.airspace-plan-data::-webkit-scrollbar {
  width: 4px;
  background: var(--white);
}

.enroute-airspace-tabpanel::-webkit-scrollbar-thumb,
.airspace-plan-data::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.airspace-tabpanel {
  height: calc(100% - 142px);
}

.airspace-plan-data {
  height: 100%;
  overflow: auto;
}

.aup-detail-btn {
  background-color: var(--white) !important;
  min-width: 150px !important;
  height: 29px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.aup-detail-btn:hover {
  background-color: var(--white) !important;
  text-decoration: underline !important;
}

.aup-close-btn {
  position: absolute;
  height: 19px;
  width: 19px;
  background: #6e3bd1c2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: 5px;
  top: -5px;
  cursor: pointer;
  z-index: 1011;
}

.airspace-map-content {
  width: 100%;
  position: absolute;
}

.enroute-radio-div {
  width: 228px;
  border-radius: 4px;
  background: #f6f6f6;
  padding: 15px 4px !important;
}

.route-plan-success {
  position: absolute;
  top: 100px;
  border: 1px solid #eb5757;
  /* background-color: yellow; */
  left: 15%;
  z-index: 999;
}

.route-plan-airspace {
  margin-bottom: 25px !important;
  z-index: 999;
}
.route-plan-airspace .leaflet-popup-content-wrapper {
  background-color: #eb5757;
  color: var(--white);
}

.route-plan-airspace .leaflet-popup-tip-container {
  left: 99.5% !important;
  top: 55px;
  z-index: 99;
  margin-left: -20px !important;
  overflow: unset;
}

.route-plan-airspace .leaflet-popup-tip-container .leaflet-popup-tip {
  width: 15px;
  height: 12px;
  transform: rotate(135deg);
  box-shadow: none !important;
  background-color: #eb5757;
  margin: -14px auto 0;
}

.route-plan-airspace .leaflet-popup-close-button span {
  color: var(--white);
}

.selected-rp-detail {
  position: absolute;
  left: 24px;
  bottom: 24px;
  z-index: 9998;
  width: 205px;
  max-height: 80px;
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
}

.route-plan-title {
  color: #696b72;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal !important;
}

.rp-route-name {
  color: #2f80ed;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal !important;
  word-break: break-all !important;
  padding-left: 24px;
  padding-right: 24px;
}
