.aixm-banner {
  width: 100%;
}

.aixm-banner .banner-text {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.aixm-banner .banner-text .aixm-title {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  color: var(--white);
  margin-bottom: 5px;
}

.aixm-banner .banner-text .aixm-desc {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: var(--white);
  margin-bottom: 24px;
}

.aixm-banner .banner-text .aixm-content {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--white);
  width: calc(100vw - 706px);

  @media (max-width: 1330px) {
    width: calc(100vw - 600px);
  }

  @media (max-width: 1200px) {
    width: calc(100vw - 500px);
  }
}

.aixm-category-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.aixm-category-chip {
  height: 56px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
}

.aixm-general-main {
  width: 100%;
  border-radius: 0;
}

.selected-general-data {
  height: calc(100% - 46px);
  overflow: auto;
}

.selected-general-data::-webkit-scrollbar {
  width: 4px;
  background: var(--white) !important;
}

.selected-general-data::-webkit-scrollbar-thumb {
  background: gray !important;
  border-radius: 2px;
}

.Text tbody tr:nth-child(even),
.general-array-body-table table tbody tr:nth-child(even),
.general-array-body-table table thead tr th {
  background-color: #ece4fb;
}

.Text tbody tr:nth-child(odd),
.general-array-body-table table tbody tr:nth-child(odd) {
  background-color: var(--white);
}

.Text tbody tr td {
  border: 1px solid #c8c7c7;
  padding: 5px 0px 5px 10px;
}

.general-array-body-table table {
  border-collapse: collapse;
}

.general-array-body-table table thead tr th,
.general-array-body-table table tbody tr td {
  border: 1px solid #c8c7c7;
  padding: 5px;
}

.general-array-body-table table thead tr th:first-child {
  width: 30%;
}

.AmdtTable tbody tr:nth-child(even) {
  background-color: #ece4fb;
}

.AmdtTable tbody tr:nth-child(odd) {
  background-color: var(--white);
}

.AmdtTable tbody tr td {
  border: 1px solid #c8c7c7;
  padding: 5px 0px 5px 10px;
}

.AmdtTable tbody tr td p,
.Text tbody tr td p {
  margin: 0;
}

.GenTable1 h6 {
  margin: 0;
  font-size: 14px;
}

.general-inr-heading {
  height: 30px;
  text-align: center;
  background-color: #f9f9f9;
  border-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: none;
  border-left-style: solid;
  border-color: #c8c7c7;
  line-height: 30px !important;
}

.enr-table thead tr th,
.enr-table tbody tr td {
  border: 1px solid #c8c7c7;
  text-align: center;
}

.enr-table thead tr th {
  background-color: #ece4fb;
}

.airport-review-map {
  width: calc(100% - 230px) !important;
}

.ad-table tbody tr:nth-child(even) {
  background-color: #ece4fb;
}
.ad-table thead tr th{
  background-color: #ece4fb !important;
}
.ad-table thead tr th,
.ad-table tbody tr td {
  border: 1px solid #c8c7c7;
}
.filter-date{
  position: relative;
  z-index: 5;
}
.ad-table tbody{
height: calc(100% - 62px);
overflow: auto;
}