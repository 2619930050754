@font-face {
  font-family: 'Manrope';
  src: local('ManropeRegular'), url('./fonts/ttf/ManropeRegular.ttf') format('truetype');
}

:root {
  --primary: #6e3bd1;
  --secondary: #828282;
  --grey: #bdbdbd;
  --darkgrey:#42444A;
  --lightgrey: #696B72;
  --black: #000000;
  --white: #ffffff;
  --lightwhite: #f3f3f3;
  --backwhite: #f2f3f6;
  --red: #eb5757;
  --btnheight32: 32px;
  --btnheight48: 48px;
  --MNRPLR: 'Manrope' !important;
}

body {
  margin: 0;
  height: 100%;
  font-family: var(--MNRPLR);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
}

code {
  font-family: 'Manrope' !important;
}

.leaflet-container{
  font-family: var(--MNRPLR);
}

textarea {
  font-size: inherit;
  font-family: 'Manrope' !important;
}
