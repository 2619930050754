@keyframes pulse {
  0% {
    box-shadow: 0 0 1px #6e3bd1, inset 0 0 0px #6e3bd1;
  }
  50% {
    box-shadow: 0 0 3px #6e3bd1, inset 0 0 2px #6e3bd1;
  }
  100% {
    box-shadow: 0 0 1px #6e3bd1, inset 0 0 0px #6e3bd1;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 0 1px #6e3bd1, inset 0 0 1px #6e3bd1;
  animation: pulse 1s linear 0.5s infinite;
  -webkit-animation: pulse 1s linear 0.5s infinite;
}
.inner1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0 0 1px #6e3bd1, inset 0 0 1px #6e3bd1;
  animation: pulse 1s linear 0.5s infinite;
  -webkit-animation: pulse 1s linear 0.5s infinite;
}
.inner1 p {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1px #6e3bd1, inset 0 0 1px #6e3bd1;
  border-radius: 50%;
  background-color: #6e3bd1;
  height: 7px;
  width: 7px;
  animation: pulse 1s linear 0.5s infinite;
  -webkit-animation: pulse 1s linear 0.5s infinite;
}
