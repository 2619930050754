/* old login page css*/
.main-login{
    display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
}
.login-area {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login-box {
  background: var(--white);
  box-shadow:  0px 20px 30px -7px rgba(0, 0, 0, 0.20);;
  border-radius: 10px;
  width: 415px;
  height: 416px;
  position: relative;
  margin-bottom: 64px;
}
.login-box-header {
  display: flex;
  justify-content: center;
 font-size: 20px !important;
  font-weight: 700 !important; 
  color: var(--lightgrey);
  position: absolute;
  top: -15px;
  left: 160px;
}
.login-form{
  padding: 64px 72px;
}
.forgot-password {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.login-footer {
  display: flex;
  justify-content: center;
}
.p-5 {
  padding: 50px;
}
.mt-4 {
  margin-top: 30px;
}

/* New login page css*/
.auth-form-box {
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  align-items: center;
  background-image: url("/src/assets/images/landingimage.png");
  background-position: center;
  background-size: cover;
}

.form-main,.forget-pass-form {
  background-color: var(--white);
  width: 480px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 96px 105px;
}

.welcome-label {
  color: var(--lightgrey);
  text-align: center;
}

.auth-form {
  padding-bottom: 16px;
}
.field-label {
  color: var(--lightgrey) !important;
  font-size: 16px !important;
  line-height: 19px !important;
  font-weight: 600 !important;
}
.field-label span{
  color: var(--red) !important;
}
.text-field {
  margin-bottom: 47px !important;
}

.login-btn {
  width: 100% !important;
  height: 40px !important;
}
.login-btn span {
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
}
.guest-btn {
  width: 100% !important;
  color: var(--primary) !important;
  padding: 8px 15px !important;
}
.guest-btn span {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.request-password-btn {
  background-color: var(--primary) !important;
}
.footer-img {
  text-align: center;
}
.powered {
  font-size: 12px !important;
  color: var(--primary);
  text-align: center;
}

/* Forgot Password */
/* .forget-pass-form {
  background-color: var(--white);
  width: 400px;
  box-sizing: border-box;
  padding: 84px 20px;
  border-radius: 10px;
  margin-right: 60px;
  height: 630px;
} */
