/* old home page css */

.brand-container {
  display: flex;
  background-color: #f2f3f6;
  width: 25vw;
  transform: perspective(1361px) rotateY(12deg) translate(-8px, 0px) scale(0.96);
}

.menu-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 5rem;
}

.menu-row {
  display: flex;
}

.row-margin {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.left-margin {
  margin-left: 5rem;
}

.brand-image {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 7rem 0;
}

.airport-logo {
  width: 160px;
}

.inetra-logo {
  width: 220px;
}

.copyright-div {
  display: flex;
  width: 112px;
  border-right: 1px solid rgba(37, 33, 92, 0.3);
}

.copyright-text {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  position: absolute;
  top: 280px;
  left: -112px;
}

/* new home page css */
.home-container {
  display: flex;
  flex: 1;
  height: 100vh;
}

.airport-logo {
  display: flex;
  justify-content: center;
}

.satsture-inetra-logo {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
}

.user-logo {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-right: 40px;
}

.dropdown-menu {
  padding: 5px 18px 0;
  width: 193px;
}

.icon-text {
  font-size: 14px !important;
  line-height: 19px !important;
  font-weight: 500 !important;
  color: #828282 !important;
}

.setting-icon {
  margin-right: 10px;
}

.logout-icon {
  margin-right: 10px;
}

.header-login-btn {
  width: 110px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.header-login-btn span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.login-icon {
  transform: rotateY(180deg);
  margin-right: 10px;
}

/* Main Details  */
.main-details {
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 0 40px;
  overflow: auto;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white) !important;
  height: 54px;
  width: 100vw;
  position: relative;
  z-index: 2;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
}

.main-grid {
  justify-content: center;
}

.grid-col {
  text-align: center;
  padding: 30px;
  background: var(--white);
}

.grid-col:hover {
  box-shadow: 0px 20px 30px -7px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}

.user-logo svg:first-child {
  margin-right: 35px;
}

.grid-col:hover .main-title {
  color: var(--primary);
}

.grid-col:hover svg path {
  stroke: var(--primary) !important;
}

.grid-col:hover svg circle {
  stroke: var(--primary) !important;
}

.grid-col:hover svg mask {
  stroke: var(--primary) !important;
}

.grid-col:hover svg rect {
  stroke: var(--primary) !important;
}

.main-title {
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: var(--lightgrey);
  text-align: center;
  text-transform: uppercase;
}

.description {
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  color: #42444a !important;
}

.extra-data {
  display: none;
  position: absolute;
  top: 30px;
  right: 30px;
}

.grid-col:hover .extra-data {
  display: block;
}

.rc-slider-mark-text-active > .livetracker-marker {
  color: unset !important;
}

.rc-slider-mark-text-active > .route-active-marker {
  color: #27ae60 !important;
}

.livetracker-marker {
  position: absolute;
  top: -23.5px;
  left: -12.5px;
}
