.welcome-area {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #424442;
}

.surveyor-category-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.admin-home {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

.surveyor-loader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}