.welcome-etod {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  color: var(--lightgrey);
  z-index: 2;
}

.main-new-airport {
  width: 100%;
}
.main-selected-airport {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main-selected-airport p {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #42444a;
}
.main-new-airport p {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--lightgrey);
  margin-top: 91px;
}

.inr-new-airport {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.select-new-airport {
  width: 538px;
  margin-right: 20px;
}
.select-new-airport .select__option:hover {
  background-color: var(--primary);
  color: var(--white);
}
.select-new-airport .select__option--is-selected {
  background-color: var(--primary);
}
.select-new-airport .select__control {
  height: 48px;
}
.select-new-airport .select__single-value {
  color: var(--primary);
  font-size: 20px;
  font-weight: 500;
}
.text-marker {
  font-weight: 900;
  font-size: 22px !important;
}

/* Etod Airport Detail*/
.surface-title {
  margin-top: 12px !important;
  margin-bottom: 6px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: var(--primary);
}

.select-title {
  margin-bottom: 10px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--grey);
}

.area-title {
  margin-bottom: 18px !important;
}

.ols-title,
.area-title {
  background: var(--white);
  border: 1px solid var(--primary);
  border-radius: 2px;
}

.detail-sidebar {
  z-index: 1001;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 276px;
}

.inr-detail-sidebar {
  height: 95.9%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.sidebar-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
}

.detail-card {
  box-shadow: none !important;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.2) !important;
  margin-bottom: 8px;
}

.airport-code {
  border: 0.5px solid var(--secondary);
  border-radius: 2px;
  background: var(--white);
}

.airport-code .detail-card-content {
  padding-left: 8px !important;
}

.airport-detail-card {
  border-radius: 0px !important;
  border-bottom: 1px dashed var(--grey);
}

.airport-detail-card:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}

.detail-card-content {
  padding: 5px 8px 8px 0px !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.airport-detail-card .detail-card-content,
.detail-card.airport-detail-card {
  background-color: transparent !important;
}

.card-data {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inr-data-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary);
  /* margin-bottom: 50px; */
}

.top-card-data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 18px !important;
  max-height: 18px !important;
}

.main-tab-list {
  background: rgba(255, 255, 255, 0.2);
  border: 0.5px solid var(--primary);
  border-radius: 2px;
  padding: 2px;
}
.rwy-tab-list {
  min-height: 30px !important;
}
.rwy-tab-list button {
  min-height: 28px;
}

.main-tab-list button {
  /* min-width: 109px; */
  background: var(--white);
  padding: 7px 8px;
}

.main-tab-list button.Mui-selected {
  background: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.detail-tab {
  padding: 0 !important;
  max-height: calc(100vh - 320px);
  overflow: auto;
}

.detail-tab::-webkit-scrollbar {
  display: none;
  width: 5px;
}

.detail-tab::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 4px;
}

.tab-title {
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  color: var(--primary);
  text-align: start;
}

.rwy-tab-list .tab-title {
  text-align: center;
}

.main-tab-list button.Mui-selected .date-direction,
.main-tab-list button.Mui-selected .tab-title {
  color: var(--white);
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
}

/* obstacle analysis */
.ols-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.ols-card-inr {
  margin-right: 70px;
}

.ols-card-inr .nav-button-container .icon-row,
.ols-card-innr .nav-button-container .icon-row {
  position: relative;
}

/* Airport Generate Surface */
.rwy-title {
  margin-top: 5px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: var(--lightgrey);
}

.airport-rwy-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-bottom: 1px dashed var(--grey);
  padding-left: 10px;
}

.airport-rwy-card .surface-data-title {
  padding-top: 8px;
  font-weight: 500;
  font-size: 12px;
  color: var(--secondary);
}

.airport-rwy-card .surface-data-value {
  width: 100%;
  padding-bottom: 8px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.template-btns {
  text-align: end;
}

.phoneno-field input::-webkit-outer-spin-button,
.phoneno-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.see-details-btn {
  padding: 0px 16px !important;
  height: var(--btnheight48) !important;
  min-width: 180px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
  letter-spacing: 0;
}
.see-details-btn:disabled {
  background-color: #cccccc !important;
}
.etod-dashboard-btn {
  padding: 10px 50px !important;
  width: 160px !important;
  height: var(--btnheight48) !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: var(--white) !important;
  background-color: var(--primary) !important;
}
